import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/category',
        name: 'category',
        component: () => import('../views/category/index.vue')
      },
      {
        path: '/customerInsight',
        name: 'customerInsight',
        component: () => import('../views/customerInsight/index.vue')
      },
      {
        path: '/marketingStrategy',
        name: 'marketingStrategy',
        component: () => import('../views/marketingStrategy/index.vue')
      },
      {
        path: '/popularFeelings',
        name: 'popularFeelings',
        component: () => import('../views/popularFeelings/index.vue')
      },
      {
        path: '/introducePage',
        name: 'introducePage',
        component: () => import('../views/introducePage/introducePage.vue')
      },
      {
        path: '/tutorialPage',
        name: 'tutorialPage',
        component: () => import('../views/tutorialPage/tutorialPage.vue')
      },
      {
        path: '/updateLog',
        name: 'updateLog',
        component: () => import('../views/updateLog/index.vue')
      },
      {
        path: '/illustrateAI',
        name: 'illustrateAI',
        component: () => import('../views/illustrateAI/illustrateAI.vue')
      },
      {
        path: '/openPlatform',
        name: 'openPlatform',
        component: () => import('../views/openPlatform/openPlatform.vue')
      },
      {
        path: '/inviteFriendsPage',
        name: 'inviteFriendsPage',
        component: () => import('../views/inviteFriendsPage/inviteFriendsPage.vue')
      },
      
      
    ]
  },
  {
    path: '/price',
    name: 'price',
    component: () => import('../views/home/price.vue')
  },
  {
    path: '/priceDialog',
    name: 'priceDialog',
    component: () => import('../views/home/priceDialog.vue')
  },
   {
    path: '/vocPage',
    name: 'vocPage',
    component: () => import('../views/illustrateAI/vocPage.vue')
  },
  {
    path: '/servicePage',
    name: 'servicePage',
    component: () => import('../views/illustrateAI/servicePage.vue')
  },
  {
    path: '/flowPath',
    name: 'flowPath',
    component: () => import('../views/flowPath/index.vue')
  },
  {
    path: '/UserAgreement',
    component: () => import('../views/h5/UserAgreement.vue')
  },
  {
    path: '/PrivacyAgreement',
    component: () => import('../views/h5/PrivacyAgreement.vue')
  },
  {
    path: '/assembly',
    name: 'assembly',
    component: () => import('../views/assembly/index.vue')
  },
  {
    path: '/loginDialogPage',
    name: 'loginDialogPage',
    component: () => import('../views/loginDialogPage/index.vue')
  },
  {
    path: '/mobileEndPage',
    name: 'mobileEndPage',
    component: () => import('../views/mobileEndPage/index.vue')
  },
  
  // {
  //   path: '/loginDIalog',
  //   name: 'loginDIalog',
  //   component: () => import('../components/loginDIalog/index.vue')
  // },
  

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
