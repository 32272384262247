<template>
  <div>
    <header class="header">
      <!-- <svgList /> -->
      <div class="section">
        <h1 class="font-size-0 flex items-center">
          <router-link :to="{ name: 'home' }">
            <img class="logo" alt="元空" src="../../assets/images/logo.png" />元空
          </router-link>
          <!-- <span class="beta-version">Beta</span> -->
        </h1>
        <!-- product: {
            header_config
            title: '产品',
            content: [
                {
                    card_tit: '酷表Chat Excel',
                    card_cont: '仅通过聊天，就最控制Excel表格'
                },
                {
                    card_tit: 'AI VOC',
                    card_cont: '借助AI挖掘数据，对话式获取产品/消费者/市场洞察'
                },
                {
                    card_tit: 'AI 智能客服',
                    card_cont: '全渠道、全流程覆盖，提供智能化、个性化服务'
                }
            ]
        },
        price: '价格',
        tutorials: '使用教程', -->
        <nav class="nav-menu">
          <div class="nav-menu-item" v-if="language == 'zh_CN'">
            <div class="nav-menu-item-inner">
              <!-- <router-link :to="{ name: 'home' }"> 产品 </router-link> -->
              <a href="javascript:;"> {{$t(`header_config.product.title`)}}</a>
              <svg class="iconpark-icon iconpark-down">
                <use href="#c-down"></use>
              </svg>
              <div class="nav-sub-menu-container">
                <div class="sub-menu-wrapper">
                  <div class="sub-menu-item-box" @click="godetail(1)">
                    <div>
                      <h5 class="sub-menu-title">{{$t(`header_config.product.content[0].card_tit`)}}</h5>
                      <p class="sub-menu-desc">
                        {{$t(`header_config.product.content[0].card_cont`)}}
                      </p>
                    </div>
                  </div>
                  <div class="sub-menu-item-box" @click="godetail(2)">
                    <div>
                      <h5 class="sub-menu-title">{{$t(`header_config.product.content[1].card_tit`)}}</h5>
                      <p class="sub-menu-desc">
                        {{$t(`header_config.product.content[1].card_cont`)}}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="sub-menu-item-box" @click="godetail(4)">
                    <div>
                      <h5 class="sub-menu-title">AI 营销辅助决策</h5>
                      <p class="sub-menu-desc">
                        借助AI挖掘数据，对话式获取产品/消费者/市场洞察
                      </p>
                    </div>
                  </div> -->
                  <div class="sub-menu-item-box" @click="godetail(3)">
                    <div>
                      <h5 class="sub-menu-title">{{$t(`header_config.product.content[2].card_tit`)}}</h5>
                      <p class="sub-menu-desc">
                        {{$t(`header_config.product.content[2].card_cont`)}}
                      </p>
                    </div>
                  </div>

                  <!-- <router-link class="sub-menu-item-box" :to="{ name: 'popularFeelings' }">
                  <img src="@/assets/images/navigation04.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">AIGA辅助决策</h5>
                    <p class="sub-menu-desc">
                      后链路数据对接,与电商订单数据整合，形成决策闭环
                    </p>
                  </div>
                </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner">
              <!-- <a href="javascript:;">价格</a> -->
              <a @click="navigateTo('#price')">{{$t(`header_config.price`)}}</a>
              <!-- <a href="#section2" @click="navigateTo('#section2')">价格</a> -->
            </div>
          </div>
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner" @click="clicktutorialPage">
              <!-- <a href="https://betasheet.chatexcel.com/#/home?tab=usingTutorials" target="_blank" >使用教程</a> -->
              <!-- <a href="./tutorialPage" >使用教程</a> -->
              <a>{{$t(`header_config.tutorials`)}}</a>
              <!-- <router-link :to="{ name: 'tutorialPage' }"> 使用教程 </router-link> -->
            </div>
          </div>
          <div class="nav-menu-item">
            <div class="nav-menu-item-inner">
              <!-- <a href="javascript:;">价格</a> -->
              <a @click="goOpen">{{$t(`header_config.openPlatform`)}} </a>
              <!-- <a href="#section2" @click="navigateTo('#section2')">价格</a> -->
            </div>
          </div>

          <div class="nav-menu-item" style="display:none">
            <div class="nav-menu-item-inner">
              <a href="javascript:;">解决方案</a>
              <svg class="iconpark-icon iconpark-down">
                <use href="#c-down"></use>
              </svg>
            </div>
            <div class="nav-sub-menu-container">
              <div class="sub-menu-wrapper">
                <router-link class="sub-menu-item-box" :to="{ name: 'category' }">
                  <img src="@/assets/images/navigation01.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />
                  <!-- <svg class="iconpark-icon" style="color: #7c48ee">
                    <use href="#pinleidapan"></use>
                  </svg> -->
                  <div>
                    <h5 class="sub-menu-title">消费者画像精准刻画</h5>
                    <p class="sub-menu-desc">
                      聆听消费者反馈，刻画用户画像，定位需求与痛点
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'marketingStrategy' }">
                  <img src="@/assets/images/navigation02.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">品牌动态实时捕捉</h5>
                    <p class="sub-menu-desc">
                      收集品牌最新信息。洞察舆情变化，预测传播走向
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'customerInsight' }">
                  <img src="@/assets/images/navigation03.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">“趋势达人”发现连接</h5>
                    <p class="sub-menu-desc">
                      发现不一样的人群，寻找人群中的KP
                    </p>
                  </div>
                </router-link>
                <router-link class="sub-menu-item-box" :to="{ name: 'popularFeelings' }">
                  <img src="@/assets/images/navigation04.png" alt="" style="width: 16px; height: 16px;margin-top: 5px;" />

                  <div>
                    <h5 class="sub-menu-title">AIGA辅助决策</h5>
                    <p class="sub-menu-desc">
                      后链路数据对接,与电商订单数据整合，形成决策闭环
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </nav>
        <div class="header-right">
          <div class="gift_div" @click="goGiftPage">
            <img class="gift_img" alt="liwu" src="../../assets/images/gift.png" />
            <span>邀好友赚会员</span>
          </div>
          <el-popover placement="left-start" style="height:100px" width="180" trigger="hover" popper-class="popoverStyle" :visible-arrow="false" v-model="visible">
            <div class="float_box" @click="goGiftPage">
              <img class="float_bg" alt="liwu" src="../../assets/images/float_bg.png" />
              <span>邀好友 赚会员</span>
            </div>
            <div class="float_div" slot="reference">
              <div class="float_img_div">
                <img class="float_img" alt="liwu" src="../../assets/images/gift.png" />
              </div>
            </div>
          </el-popover>

          <!-- <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link" style="margin-right: 20px;">
              {{ { zh_CN: '中文', en_US: 'English', ja_US: '日本語' }[$store.state.language] }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh_CN">中文</el-dropdown-item>
              <el-dropdown-item command="en_US">English</el-dropdown-item>
              <el-dropdown-item command="ja_US">日本語</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <!-- {{$t(`global.contactUs`)}} -->
          <button v-if="!$store.state.login_token" @click="loginChange" class="login-btn">
            {{$t(`global.login_btn`)}}
          </button>
          <div v-else class="user-info">
            <button class="get-started-btn" @click="$toUse()">{{$t(`global.enter_btn`)}}</button>
            <div class="user-card">
              <img class="user-avatar" alt="元空" src="../../assets/images/image-avatar.png" />
              <span class="account">

                {{ $store.state.mobile? $store.state.mobile:$store.state.source=='wx'? '微信用户':'' }}
              </span>
              <svg class="iconpark-icon">
                <use href="#c-sanjiao-xia"></use>
              </svg>
              <div class="log-out-box">
                <div class="log-out">
                  <span id="loginOut" @click="logOuts"> {{$t(`global.outLog_btn`)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import svgList from "@/components/svgList";
import { clearCookies, getCookie, setCookie, } from "@/utils/cookie";
import { logOut, effectiveMeal } from "@/api/user.js";
import zhCN from '../../i18n/lang/zh-CN'
import enUS from '../../i18n/lang/en'
import { domainName } from '@/utils/domainName'

export default {
  name: "navHeader",
  components: { svgList },
  data() {
    return {
      language: this.$store.state.language,//多语言
      visible: false
    };
  },
  mounted() {
    console.log(this.$store.state)
    if (!getCookie("language")) {
      this.$store.commit("setLanguage", 'zh_CN');
      location.reload()
    }
    // this.$store.commit("setLoginDisplay", true);



  },
  methods: {
    // 语言切换
    handleCommand(command) {
      this.language = command
      this.$i18n.locale = command // 设置给本地的i18n插件 
      console.log(command, this.language);
      this.$store.commit("setLanguage", this.language);
      this.$message({
        showClose: true,
        type: 'success',
        message: (command == 'zh_CN' ? '切换语言成功' : 'Successfully switched languages') + command,
        duration: 1000,
        center: true
      });
      location.reload()
      // this.$message.success('切换语言成功' + command)
    },
    // 点击使用教程
    clicktutorialPage() {
      console.log(76543)

      if (getCookie("access_token")) {
        if (!getCookie("mobile")) {
          this.$store.commit("setLoginDisplay", true);
          return
        }
        this.$router.push({
          name: 'tutorialPage',
        })
      }
    },
    godetail(type) {

      if (getCookie("access_token")) {
        if (!getCookie("mobile")) {
          this.$store.commit("setLoginDisplay", true);
          return
        }
        if (type == 1) {
          location.href = domainName.api1
        } else if (type == 2) {
          // this.$router.push({
          //   name: 'illustrateAI',
          //   query: {
          //     pagename: 'vocPage'
          //   }
          // })
          effectiveMeal({
            access_token: getCookie("access_token"),
          }).then((res) => {
            if (res.data.code === 0) {
              if (res.data.data.type.includes('voc')) {
                location.href = domainName.api9 + '/#/analysis'
              } else {
                this.$router.push({
                  name: 'illustrateAI',
                  query: {
                    pagename: 'vocPage'
                  }
                })
              }
            }
          });
          // location.href('https://betaworkspace.chatexcel.com/#/analysis')
        } else if (type == 3) {
          this.$router.push({
            name: 'illustrateAI',
            query: {
              pagename: 'servicePage'
            }
          })
          // window.open('https://zs.yuankongai.com/')
        } else if (type == 4) {
          location.href = 'https://yuankongai.com/'
        }
      } else {
        this.$store.commit("setLoginDisplay", true);
      }
    },
    goOpen() {
      this.$router.push({
        name: 'openPlatform',
      })
    },
    loginChange() {
      this.$store.commit("setLoginDisplay", true);
    },
    logOuts() {
      let that = this
      // let queryParam = JSON.parse(getCookie('queryParams'))
      this.$confirm(this.language == 'zh_CN' ? zhCN.global.outlog_tips : this.language == 'en_US' ? enUS.global.outlog_tips : '', this.language == 'zh_CN' ? zhCN.global.tips : this.language == 'en_US' ? enUS.global.tips : '', {
        confirmButtonText: this.language == 'zh_CN' ? zhCN.global.sure_btn : this.language == 'en_US' ? enUS.global.sure_btn : '',
        cancelButtonText: this.language == 'zh_CN' ? zhCN.global.cancel : this.language == 'en_US' ? enUS.global.cancel : '',
        type: "warning",
      })
        .then(() => {
          logOut({ access_token: getCookie("access_token") }).then((res) => {
            if (res.data.code == 0) {
              clearCookies();
              that.$store.commit("setloginToken", "");
              setCookie('userInfo', '')
              that.$store.commit("setMobile", "");
              localStorage.clear();
              // setCookie('queryParams', JSON.stringify(queryParam))
              location.href = domainName.api1
            }
          });

        })
        .catch(() => { });
    },
    navigateTo(anchor) {
      // 获取当前路由的名称
      const currentRouteName = this.$route.name;

      // 如果当前路由是主页
      if (currentRouteName === 'home') {
        // 滚动到指定的锚点
        const element = document.querySelector(anchor);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          // this.$router.replace({ name: 'home' })
        }
      }
      // else {
      //   // 否则导航到主页
      //   this.$router.push({ name: 'home' }).then(() => {
      //     const element = document.querySelector(anchor);
      //     if (element) {
      //       element.scrollIntoView({ behavior: 'smooth' });
      //     }
      //   });
      // }
    },
    // 去邀请好友页面
    goGiftPage() {
      this.visible = !this.visible
      if (getCookie("access_token")) {
        if (!getCookie("mobile")) {
          this.$store.commit("setLoginDisplay", true);
          return
        }
        this.$router.push({
          name: 'inviteFriendsPage',
        })
      } else {
        this.$store.commit("setLoginDisplay", true);
      }
    },
  },
};
</script>



<style scoped>
.float_div {
  position: fixed;
  top: 200px;
  right: 20px;
  cursor: pointer;
}
.float_box {
  position: relative;
  cursor: pointer;
}
.float_box .float_bg {
  width: 180px;
  border-radius: 10px;
}
.float_box span {
  position: absolute;
  top: 30px;
  left: 32px;
  font-size: 18px;
  color: rgb(126, 98, 68);
}
.float_div .float_img_div {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgb(248, 148, 148),
    rgb(255, 255, 255)
  );
  text-align: center;
}
.float_div .float_img_div img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-top: 13px;
}
.el-dropdown {
  cursor: pointer;
}
.gift_div {
  background: linear-gradient(to right, rgb(248, 204, 166), rgb(248, 145, 67));
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 4px 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
}
.gift_div img {
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
  margin-right: 4px;
}
</style>

<style>
.el-popover.popoverStyle {
  padding: 0;
  background: none;
  box-shadow: none;
  border: none;
}
</style>