
// document.domain = "192.168.1.6";
// document.domain = "yingkeyun.com";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import i18n from './i18n'
import 'element-ui/lib/theme-chalk/index.css';
import './permission'
import "./styles/index.css";
import "./styles/common.css";
import "./styles/canToDo.css";
import { getCookie } from './utils/cookie';
import { domainName } from '@/utils/domainName'
Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.$toUse = function () {
  const loginToken = getCookie("login_token");
  if (loginToken) {
    if (!getCookie("mobile")) {
      store.commit("setLoginDisplay", true);
      return
    }
    location.href=domainName.api9
    // open('https://betaworkspace.chatexcel.com')
  } else {
    store.commit("setLoginDisplay", true);
  }
}


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
